'use strict';

const processInclude = require('base/util');

$(() => {
    processInclude(require('./product/detail'));
    processInclude(require('./components/dropdown'));
    require('./product/clickAndCollect').initRedesign();
    require('./product/stockNotification').init();
    require('./util/breadcrumbs').initPDP();
    require('./product/reviews').init();
});

require('./thirdParty/magnific');
