'use strict';

let timeOutMobileId;

const MiniCartFlyout = {};

MiniCartFlyout.showAfterAddToCart = (content, showMobile) => {
    if (showMobile) {
        MiniCartFlyout.showMobile(content);
        MiniCartFlyout.onMobileClose();
        MiniCartFlyout.hideAfterDurationMobile();
    } else {
        MiniCartFlyout.show(content);
        MiniCartFlyout.hideAfterDuration();
        MiniCartFlyout.hideOnClickBody();
    }
};

MiniCartFlyout.show = (content) => {
    const $miniCartPopover = $('.minicart .popover');
    $miniCartPopover.show();
    $miniCartPopover.addClass('popover--addtocart-flyout');
    setTimeout(function () {
        $miniCartPopover.addClass('show');
    }, 200);
    $miniCartPopover.html(content);
};

MiniCartFlyout.showMobile = (content) => {
    const $content = $(content).filter('.addtocart');
    if (!$('.addtocart').length) {
        $('body').append($content.prop('outerHTML'));
    }
    const $addToCart = $('.addtocart');
    $addToCart.show();
    setTimeout(function () {
        $addToCart.addClass('show');
    }, 200);
    $addToCart.html($content.html());
};

MiniCartFlyout.hide = () => {
    const $miniCartPopover = $('.minicart .popover');
    $miniCartPopover.removeClass('show');
    setTimeout(function () {
        $miniCartPopover.empty();
        $miniCartPopover.hide();
        $miniCartPopover.removeClass('popover--addtocart-flyout');
    }, 200);
};

MiniCartFlyout.hideMobile = () => {
    if (timeOutMobileId) {
        clearTimeout(timeOutMobileId);
        timeOutMobileId = null;
    }
    const $addToCart = $('.addtocart');
    $addToCart.removeClass('show');
    setTimeout(function () {
        $addToCart.empty();
        $addToCart.hide();
    }, 200);
};

MiniCartFlyout.onMobileClose = () => {
    $('body').on('touchend', '.cart-flyout--add-to-cart', (e) => {
        e.stopPropagation();
    });
    $('body').on('touchend', '.addtocart--mobile-flyout', () => {
        MiniCartFlyout.hideMobile();
    });
};

MiniCartFlyout.hideAfterDuration = () => {
    const $addToCart = $('.addtocart');
    const duration = $addToCart.data('notification-flyout-duration');
    const timeoutId = setTimeout(() => {
        MiniCartFlyout.hide();
    }, duration);
    $('.minicart').on('mouseenter focusin touchstart', () => {
        clearTimeout(timeoutId);
    });
};

MiniCartFlyout.hideAfterDurationMobile = () => {
    const $addToCart = $('.addtocart');
    const duration = $addToCart.data('notification-flyout-duration-mobile');
    timeOutMobileId = setTimeout(() => {
        MiniCartFlyout.hideMobile();
    }, duration);
};

MiniCartFlyout.hideOnClickBody = () => {
    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            MiniCartFlyout.hide();
        }
    });
};

module.exports = MiniCartFlyout;
