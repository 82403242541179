'use strict';

var base = require('./base');
const ProductSlider = require('./slider');
const isMobile = require('../util/isMobile');
const Dialog = require('../util/dialog');
const Wishlist = require('../product/wishlistHeart');
const MiniCartFlyout = require('../components/miniCartFlyout');
const Login = require('../login/login');
const MagnificPopup = require('./magnificPopup');
const Slider = require('../util/slider');
const isVisible = require('../util/isVisible');
const RavotclubHelper = require('../util/ravotclubHelper');

const addToCartNotificationTypes = {
    POPUP: 'popup',
    FLYOUT: 'flyout'
};

const showAfterAddToCartPopup = content => {
    const PageFlyout = require('../util/pageFlyout');

    $('.js-addToCart-flyout').find('.page-flyout__container').html(content);

    PageFlyout.open(
        '.js-addToCart-flyout',
        () => {
            const sliderOptions = {
                slidesToShow: {
                    desktop: 2
                }
            };
            ProductSlider.initSlider(sliderOptions);
            ProductSlider.initMutationObserver(sliderOptions);
            $('.product-slider img').on('error', function () {
                $(this).attr('src', $(this).data('404')).siblings('source').attr('srcset', $(this).data('404'));
            });
        }
    );
    PageFlyout.onClose('.js-addToCart-flyout', '.js-close-addToCart-flyout');
};

const showAfterAddToCartFlyout = (content, showMobile) => {
    MiniCartFlyout.showAfterAddToCart(content, showMobile);
};

/**
 * Selects a size by preference or whether it is a one size product or not.
 */
const initPreselectSize = () => {
    if ($('.product-detail--outofstock').length || $('.js-wishlist-landing').length) return;
    const preferredSize = new URL(location.href).searchParams.get('preferredSize');
    if (preferredSize && !isNaN(preferredSize)) {
        $(`.size-button[data-attr-value="${preferredSize}"]`).first().trigger('click', ['nogddl']);
    } else {
        const sizeButton = $('.size-button').first();
        if (sizeButton && sizeButton.data('attrValue') === '1size') {
            $('.size-button[data-attr-value="1size"]:not(.selected)').trigger('click', ['nogddl']);
        }
    }
};

const handleAjaxRavotclubPopup = (url) => {
    $.ajax({
        url: url,
        type: 'get',
        success: function (data) {
            $.spinner().stop();
            RavotclubHelper.showRavotterModal(data);
        }
    });
};

const handleMobileCheckoutVisibility = () => {
    if (!isMobile.any() || $('.product-set-detail').length) return;

    let hasScrolled = false;
    $(window).scroll(() => {
        hasScrolled = true;
    });
    setInterval(() => {
        if (!hasScrolled) return;

        hasScrolled = false;
        const $mobileCheckoutFixed = $('.mobile-checkout-fixed');
        const $addToCartActions = $('.prices-add-to-cart-actions');
        const isAddToCartActionsVisible = isVisible($addToCartActions);
        $mobileCheckoutFixed.toggleClass('mobile-checkout-fixed--hidden', isAddToCartActionsVisible);
        $mobileCheckoutFixed.toggleClass('mobile-checkout-fixed--visible', !isAddToCartActionsVisible);
        if (isAddToCartActionsVisible) {
            $('.mobile-checkout-fixed .size-select-fixed').addClass('d-none');
        }
    }, 250);
};

const updateEco = (response) => {
    const $ecoHolder = $('.js-eco-holder');
    const eco = response.data.product.eco;
    const hasAttributeSet = Object.keys(eco).some(key => {
        return eco[key].hasAttributeSet;
    });

    if (!hasAttributeSet) {
        $ecoHolder.removeClass('d-lg-block');
        return;
    }

    $ecoHolder.addClass('d-lg-block');
    Object.keys(eco).forEach(key => {
        $ecoHolder.find(`.eco__item[data-eco-key="${key}"]`).toggleClass('d-none', !eco[key].hasAttributeSet);
    });
};

const updateStockNotification = (res) => {
    let sizeAttrObj;
    res.data.product.variationAttributes.forEach((variationObj) => {
        if (variationObj.id.toLowerCase() === 'size') {
            sizeAttrObj = variationObj;
        }
    });

    if (sizeAttrObj) {
        sizeAttrObj.values.forEach((val) => {
            const sizeBtn = $(`.size-button[data-attr-value="${val.value}"]`);
            sizeBtn.parent().find('.js-size-notify-display').toggleClass('d-none', !!val.selectable || !val.productID || !val.productID.length);
            sizeBtn.toggleClass('js-size-notify', !val.selectable && !!val.productID && val.productID.length > 0);
        });
    }
};

module.exports = {
    initMobileCheckout: function () {
        $('.mobile-checkout-size-toggle').on('click', (e) => {
            const $this = $(e.currentTarget);
            const $productContainer = $this.parents('.product-detail').first();
            const $productSizeSelectedFixed = $productContainer.find('.size-select-fixed');
            $('.size-select-fixed').not($productSizeSelectedFixed[0]).addClass('d-none');
            $productSizeSelectedFixed.toggleClass('d-none');
        });
        $('.size-select-close').on('click', () => {
            $('.size-select-fixed').addClass('d-none');
        });
        $('body').on('product:afterAttributeSelect', (e, response) => {
            const $target = $(response.target);
            const $productContainer = response.container;
            if ($target.hasClass('selected')) {
                $productContainer.find('.mobile-checkout-size-toggle__selected-size').text($target.data('attr-display-value'));
                $productContainer.find('.size-select-fixed').addClass('d-none');
            } else {
                $productContainer.find('.mobile-checkout-size-toggle__selected-size').empty();
            }
        });
        handleMobileCheckoutVisibility();
    },

    initMagnific: function () {
        if ($('.product-set-detail').length || $('.js-wishlist-landing').length) return;

        MagnificPopup.init();
    },

    initCarousel: function () {
        const sliderSelector = '.slickCarousel .carousel-inner';
        if ($('.product-set-detail').length) {
            Slider.initializeSlider(sliderSelector, isMobile.any() ? 1.1 : 1, !isMobile.any(), 1, null, null, false, false);
        }
    },
    availability: base.availability,
    initRecaptcha: base.initRecaptcha,
    addToCart: function () {
        $(document).on('click', 'button.add-to-cart', function () {
            $('body').trigger('product:beforeAddToCart', this);

            let addToCartUrl;
            let addToCartModalUrl;
            let pid;
            let pidsObj;
            let hasError = false;
            const $buttonPres = $(this);
            const $productContainer = $(this).closest('.product-detail');

            if ($buttonPres.is('.add-to-cart')) {
                $productContainer.find('.product-attributes div[data-attr].swatch').each(function () {
                    var $dataThis = $(this);
                    var $attrName = $(this).attr('data-attr');
                    if ($dataThis.find('span.selected').length === 0 && $dataThis.find('a.selected').length === 0 && ($dataThis.find('li.selected').length === 0 || $dataThis.find('li.selected').data('index') === 0)) {
                        hasError = true;
                        $dataThis.find('.attribute-name').addClass('error');
                        $productContainer.find(`p.error-${$attrName}`).removeClass('d-none');
                        if ($buttonPres.parents('.mobile-checkout-fixed').length && $attrName === 'size') {
                            $('.mobile-checkout-fixed .size-select-fixed').removeClass('d-none');
                        }
                    } else {
                        $dataThis.find('.attribute-name').removeClass('error');
                        $productContainer.find(`p.error-${$attrName}`).addClass('d-none');
                    }
                });
            }

            if (hasError) {
                $.spinner().stop();
                return;
            }

            pid = base.getPidValue($(this));

            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = base.getAddToCartUrl();
            addToCartModalUrl = base.getAddToCartModalUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: base.getChildProducts(),
                quantity: base.getQuantitySelected($(this))
            };

            if (!$('.bundle-item').length) {
                form.options = base.getOptions($productContainer);
            }

            if (!form.quantity) { form.quantity = 1; }

            if ($('.js-wishlist-landing').length) {
                form.shouldRemoveProductFromWishlist = true;
                form.pidToRemoveFromWishlist = $productContainer.find('.remove-from-wishlist').data('pid');
            }

            $(this).trigger('updateAddToCartFormData', form);

            if (addToCartUrl && addToCartUrl !== 'null') {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        // base.handlePostCartAdd(data); commented to remove bonus product pop up
                        $('.minicart').trigger('count:update', data);

                        if (data.quantityTotal && !isNaN(data.quantityTotal)) {
                            $('.minicart-quantity').data('item-count', data.quantityTotal);
                        }

                        if (data.abandonedCartMessage) {
                            $('.minicart').trigger('abandonedcartmessage:update', data.abandonedCartMessage);
                        }

                        const afterAddToCartData = data;
                        afterAddToCartData.$productContainer = $productContainer;
                        $('body').trigger('product:afterAddToCart', afterAddToCartData);
                        if (!data.error) {
                            $.ajax({
                                type: 'GET',
                                url: addToCartModalUrl,
                                data: {
                                    pid: pid,
                                    isMobile: isMobile.any()
                                },
                                success: function (content) {
                                    const $addToCart = $(content).filter('.addtocart');
                                    const notificationType = $addToCart.data('notification-type');
                                    if (isMobile.any()) {
                                        showAfterAddToCartFlyout(content, true);
                                    } else if (notificationType === addToCartNotificationTypes.POPUP) {
                                        showAfterAddToCartPopup(content);
                                    } else if (notificationType === addToCartNotificationTypes.FLYOUT) {
                                        showAfterAddToCartFlyout(content, false);
                                    }
                                    $.spinner().stop();
                                },
                                error: function () {
                                    $.spinner().stop();
                                }
                            });
                        } else {
                            $.spinner().stop();
                            Dialog.show(Dialog.TYPE_ERROR, data.labels.title, data.message, {
                                cancel: {
                                    text: data.labels.accept
                                }
                            });
                        }
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },

    colorAttribute: base.colorAttribute,

    selectAttribute: base.selectAttribute,

    updateAttributesAndDetails: function () {
        $('body').on('product:statusUpdate', function (e, data) {
            if (data.attributes) {
                /** @type {Array<Object>} */
                const attributeGroups = data.attributes;

                attributeGroups.forEach(function (attributeGroup) {
                    if (attributeGroup.ID === 'productDescription') {
                        if (attributeGroup.attributes && attributeGroup.attributes.length > 0) {
                            var attributes = attributeGroup.attributes;
                            attributes.forEach(function (attribute) {
                                if (attribute.id === 'siteDescription') {
                                    if (attribute.value && attribute.value.length > 0) {
                                        $('.attribute-siteDescription').html(attribute.value[0]);
                                    }
                                }
                            });
                        }
                    }
                });
            }
        });
    },
    showSpinner: function () {
        $('body').on('product:beforeAddToCart', function () {
            $.spinner().start();
        });
        $('body').on('product:beforeAttributeSelect', function () {
            $.spinner().start(true);
        });
    },
    updateAttribute: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            response.container.find('.product-attributes div[data-attr].swatch').each(function () {
                var $dataThis = $(this);
                var $attrName = $(this).attr('data-attr');
                $(this).parents('.js-productAttributes').find('.js-wishlistTile').attr('data-pid', response.data.product.colorVariantID || response.data.product.id);
                $(this).parents('.js-productAttributes').find('.js-wishlistTile').removeClass('active');
                Wishlist.initProducts();

                if (!($dataThis.find('span.selected').length === 0 && $dataThis.find('a.selected').length === 0)) {
                    $dataThis.find('.attribute-name').removeClass('error');
                    $(`p.error-${$attrName}`).addClass('d-none');
                }
            });

            if ($('.product-detail>.bundle-items').length) {
                response.container.data('pid', response.data.product.id);
                response.container.find('.product-id').text(response.data.product.id);
                response.container.find('.product-colorVariantID').text(response.data.product.colorVariantID || response.data.product.id);
            } else if ($('.product-set-detail').eq(0)) {
                response.container.data('pid', response.data.product.id);
                response.container.find('.product-id').text(response.data.product.id.length > 7 ? response.data.product.id : response.data.product.colorVariantID);
                response.container.find('.product-colorVariantID').text(response.data.product.colorVariantID || response.data.product.id);
                response.container.find('.low-stock-message').toggleClass('d-none', !response.data.product.showLowStockMessage);
            } else {
                $('.product-id').text(response.data.product.colorVariantID || response.data.product.id);
                $('.product-colorVariantID').text(response.data.product.colorVariantID || response.data.product.id);
                $('.product-detail:not(".bundle-item")').data('pid', response.data.product.id);
            }

            updateEco(response);
            updateStockNotification(response);
        });
    },
    updateAddToCart: function () {
        $('body').on('product:updateAddToCart', function () {
            // update local add to cart (for sets)
            $('.product-availability').toArray().every(function (item) {
                return $(item).data('available') && $(item).data('ready-to-order');
            });
        });
    },
    updateAvailability: function () {
        $('body').on('product:updateAvailability', function (e, response) {
            $('div.availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available);

            $('.availability-msg', response.$productContainer)
                .empty().html(response.message);

            if ($('.global-availability').length) {
                var allAvailable = $('.product-availability').toArray()
                    .every(function (item) { return $(item).data('available'); });

                var allReady = $('.product-availability').toArray()
                    .every(function (item) { return $(item).data('ready-to-order'); });

                $('.global-availability')
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $('.global-availability .availability-msg').empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            }
        });
    },
    sizeChart: function () {
        var $sizeChart = $('.size-chart-collapsible');
        $('.size-chart a').on('click', function (e) {
            e.preventDefault();
            var url = $(this).attr('href');
            if ($sizeChart.is(':empty')) {
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        $sizeChart.append(data.content);
                    }
                });
            }
            $sizeChart.toggleClass('active');
        });

        $('body').on('click touchstart', function (e) {
            if ($('.size-chart').has(e.target).length <= 0) {
                $sizeChart.removeClass('active');
            }
        });
    },
    initErrorImage: function () {
        $('.swatch-image').on('error', function () {
            $(this).attr('src', $(this).data('404'));
        });
    },
    initPreselectSize: initPreselectSize,
    initRavotPopups: function () {
        $('body').on('hidden.bs.modal', '#ravotterModal[data-refresh="true"], #ravotterThankyouModal', function (e) {
            e.preventDefault();
            window.location.reload();
        });
    },
    ravotclubLabelButton: function () {
        $('body').on('click', '.js-ravotclub-label-button', function (e) {
            e.preventDefault();
            $.spinner().start(true);

            var url = $(this).data('url'); // Product-Ravotclub
            handleAjaxRavotclubPopup(url);
        });
    },
    handleChangeRavotMember: function () {
        $('body').on('change', '#ravotclubMemberSwitch', function () {
            var checked = $(this).is(':checked');
            $('.submit-become-ravotclub').prop('disabled', !checked);
        });

        $('body').on('click', '.submit-become-ravotclub', function () {
            var url = $(this).data('url'); // Account-ChangeRavotMember

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {
                    member: true
                },
                success: function () {
                    $('#ravotterModal').modal('hide');
                    $('#ravotterThankyouModal').modal('show');
                }
            });
        });
    },
    handleAddRavotMember: function () {
        RavotclubHelper.handleAddRavotclubMember();
    },
    handleLoginPopup: function () {
        Login.onLoginSubmit('.login-ravotter', () => {
            // show next popup
            var button = $('.js-ravotclub-label-button');
            var url = button.data('url'); // Product-Ravotclub
            handleAjaxRavotclubPopup(url); // show new popup after login

            $('#ravotterModal').attr('data-refresh', true);

            // update header
            var container = $('.js-account-header');
            var updateUrl = container.data('url');

            $.ajax({
                url: updateUrl,
                type: 'get',
                success: function (data) {
                    container.html(data);
                }
            });
        });
    },
    initContentSlider: function () {
        const slidesToShow = isMobile.any() ? 2.2 : 4;
        const showArrows = !isMobile.any();
        const slidesToScroll = isMobile.any() ? 2 : 4;
        Slider.initializeSlider('.pdp-content-slider', slidesToShow, showArrows, slidesToScroll, null, '.pdp-content-slider', true);
    }
};
