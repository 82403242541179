'use strict';

const showNotificationPopup = () => {
    $('body').on('click', '.js-size-notify', function () {
        const ProductSize = $(this).data('attr-value');
        const sizeProductId = $(this).data('product-id');

        $.ajax({
            url: $('.js-stocknotification-modalcontent').data('subform'),
            type: 'get',
            data: {
                pid: sizeProductId,
                size: ProductSize
            },
            success: function (data) {
                const $modal = $('#stockNotificationModal');
                $modal.find('.modal-content').html(data);
                $modal.modal('show');
            }
        });
    });
};

const handleFormSubmission = () => {
    $('body').on('submit', '.js-stockNotificationForm', function (e) {
        e.preventDefault();
        const form = e.currentTarget;
        const url = form.action;

        $('.stockNotification').spinner().start(true);

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $(form).serialize(),
            success: function (data) {
                if (data.html) {
                    const $modal = $('#stockNotificationModal');
                    $modal.find('.modal-content').html(data.html);
                }
            },
            complete: function () {
                $('.stockNotification').spinner().stop();
            }
        });

        return false;
    });
};

const init = () => {
    showNotificationPopup();
    handleFormSubmission();
};

module.exports = {
    init: init
};
