'use strict';

const PageFlyout = require('../util/pageFlyout');

const Reviews = {};

Reviews.init = () => {
    Reviews.loadProductReviews();
};

Reviews.loadProductReviews = () => {
    const $ratingsButtonHolder = $('.js-ratings-holder');
    const url = $ratingsButtonHolder.data('get-product-reviews-url');
    if (!url) return;

    $.get(url, (data) => {
        if (data.error || data.amount < 1) return;

        Reviews.updateRatingsButton($ratingsButtonHolder, data.ratingsButtonHtml);
        Reviews.updateFlyoutContent(data);
        $('body').trigger('product:reviewsInfo', [data]); // GDDL
        PageFlyout.init('.js-reviews-flyout', '.js-open-reviews', null, null, null);
    });
};

Reviews.updateRatingsButton = ($ratingsButtonHolder, html) => {
    $ratingsButtonHolder.empty().append(html);
};

Reviews.updateFlyoutContent = (data) => {
    const $flyout = $('.js-reviews-flyout');
    const $amount = $flyout.find('.js-reviews-amount');
    const $summary = $flyout.find('.js-reviews-summary');
    const $overview = $flyout.find('.js-reviews-overview');
    $amount.empty().append(`(${data.amount})`);
    $summary.empty().append(data.flyoutSummary);
    $overview.empty().append(data.flyoutOverview);
};

module.exports = Reviews;
