'use strict';

const formValidation = require('../components/formValidation');
const Recaptcha = require('../util/recaptcha');
const Dialog = require('../util/dialog');

const Login = {};

Login.initLoginPage = () => {
    Login.onLoginSubmit('form.login', (data) => {
        location.href = data.redirectUrl;
    });
};

Login.onLoginSubmit = (loginFormSelector, callbackOnSuccess) => {
    $('body').on('submit', loginFormSelector, (event) => {
        event.preventDefault();
        const form = $(event.currentTarget);
        const url = form.attr('action');

        if (url.indexOf('isSafeSize') >= 0) {
            return false;
        }

        form.spinner().start();
        $(document).trigger('login:submit', {
            $form: form
        });

        Recaptcha.generateToken('account_login').then((token) => {
            let requestData = form.serialize();
            requestData += '&g-recaptcha-response=' + token;
            $.ajax({
                url: url,
                type: 'post',
                data: requestData,
                success: (data) => {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $(document).trigger('login:error', data);
                    } else {
                        $(document).trigger('login:success', data);
                        callbackOnSuccess(data);
                    }
                },
                error: (data) => {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $(document).trigger('login:error', data);
                        form.spinner().stop();
                    }
                }
            });
        }).catch(function () {
            $.spinner().stop();

            const errMsg = $('.error-messaging').data('uncaught-error-msg');
            const errTitle = $('.error-messaging').data('uncaught-error-title');
            Dialog.show(Dialog.TYPE_ERROR, errTitle, errMsg, {
                accept: {
                    text: 'OK'
                }
            });
        });

        return false;
    });
};

module.exports = Login;
